import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React, { CSSProperties, FC } from "react";
import translate from "src/lib/translate";
import bgImage from "src/ui/assets/scale-image-shadow.webp";
import vecMask from "src/ui/assets/scale-vec-mask.svg";

const ProgressBar = styled.div`
  label: ProgressBar;
  width: 99px;
  aspect-ratio: 99/104;
  background-color: white;
  mask: url("${vecMask}");
  mask-size: cover;
  position: absolute;
  right: 27px;
  top: 13px;
`;

const WaveAnimation = keyframes`
  0% {
    trnsform: translateY(0);
  }
  25% {
    transform: translateY(1%);
  }
  50% {
    transform: translateY(0.5%);
  }
  75% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const ProgressIndicatorGradient = styled.div`
  label: Gradient;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
  overflow: hidden;
  animation: ${WaveAnimation} 1.4s linear infinite alternate;

  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    --off-top: 20px;
    height: calc(var(--full-percent, 0%) + var(--off-top));
    background-image: linear-gradient(
      to bottom,
      #80aeff 0%,
      #f7bde6 50%,
      #ffbd70 100%
    );
    clip-path: path("M0 17C0 17 30 20 50 20C 72 20 99 17 99 17V 200H 0V 17 Z");
    transition: height 1s cubic-bezier(0.38, 0.7, 0.125, 1);
    transition-delay: 0.3s;
  }
`;

const BackDrop = styled.div`
  label: BackDrop;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 100%;
  aspect-ratio: 1/1;
`;

const ProgressText = styled.div`
  label: ProgressText;
  position: absolute;
  top: 60px;
  width: 99px;
  color: var(--color-charcoal);
  right: 27px;
  font-size: 1.625rem;
  text-align: center;
  font-weight: 500;
  letter-spacing: -0.045rem;
  pointer-events: all;

  span {
    font-size: 2.25rem;
  }
`;

const OffsetCenter = styled.div`
  position: relative;
  top: 0;
  left: 50%;
  width: 320px;
  transform: translate(-76%, 0);
  height: 100%;
  pointer-events: none;
`;

const ProgressWrap = styled.div`
  position: relative;
  width: 320px;
  height: 258px;
  height: 115px;
  margin-bottom: 1.5rem;
`;

const TOTAL_NUM = 7;

const ProgressIndicator: FC<{ count: number }> = (props) => {
  const label = translate("dailyReadings.aria.progress", {
    count: props.count
  });
  return (
    <OffsetCenter
      style={
        {
          "--full-percent": `${Math.round((props.count / TOTAL_NUM) * 100)}%`
        } as CSSProperties
      }
    >
      <ProgressWrap>
        <BackDrop />
        <ProgressBar>
          <ProgressIndicatorGradient />
        </ProgressBar>
        <ProgressText aria-label={label} title={label}>
          <span>{props.count}</span>/{TOTAL_NUM}
        </ProgressText>
      </ProgressWrap>
    </OffsetCenter>
  );
};

export default ProgressIndicator;
