import styled from "@emotion/styled";
import React, { FC, useEffect, useRef, useState } from "react";
import confettiVideo from "src/ui/assets/confetti.webm";

const AnimationWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  video {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &[data-playing="true"] {
    opacity: 1;
  }
`;

const ConfettiAnimation: FC<{ className?: string; playTimeout?: number }> = ({
  className,
  playTimeout = 0
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const to = setTimeout(() => {
      if (!videoRef.current) return;
      setIsPlaying(true);
      void videoRef.current.play();
    }, playTimeout);

    return () => clearTimeout(to);
  }, [videoRef, playTimeout]);

  return (
    <AnimationWrapper className={className} data-playing={String(isPlaying)}>
      <video muted playsInline ref={videoRef}>
        <source src={confettiVideo} type="video/webm" />
      </video>
    </AnimationWrapper>
  );
};

export default ConfettiAnimation;
