import styled from "@emotion/styled";
import { Dayjs } from "dayjs";
import React from "react";
import type { FC } from "react";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";

const DISPLAY_DAYS = 9;

const CalendarWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 3.2rem);
  height: calc(var(--scale) * 5);
  margin: 1.6rem;
  overflow: hidden;
  margin-left: -1.6rem;
  position: relative;
  --item-width: min(calc(100% - 0.5rem), 2.4rem);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1.6rem;
    height: 100%;
    background-image: linear-gradient(
      to right,
      var(--color-cream),
      transparent
    );
    z-index: 2;
  }

  &::after {
    left: auto;
    right: 0;
    background-image: linear-gradient(to left, var(--color-cream), transparent);
  }
`;

const HorizontalCalendar = styled.div`
  display: flex;
  width: calc(calc(100% - 16%) * ${(DISPLAY_DAYS / 7).toFixed(3)});
  margin: 1.6rem 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  zip-index: 1;
`;

const DayItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0;
  flex: 1;
  font-size: calc(var(--font-size) * 0.9);
`;

const IconSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--item-width);
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--Secondary-Dark-Cream, #f2efe7);
  position: relative;
  overflow: hidden;

  svg {
    --icon-size: calc(90% - 4px);
    width: var(--icon-size);
    height: var(--icon-size);
    position: absolute;
    display: none;
  }

  &[data-has-reading="true"] {
    background: linear-gradient(
        to bottom right,
        #ffdfb4 0%,
        #fddee9 51.5625%,
        #c1d7fb 100%
      ),
      linear-gradient(to right, #0e3763 0%, #5b4855 52.6042%, #8a6947 100%);

    .icon-has-reading {
      display: block;
    }
  }

  &[data-is-missed="true"] {
    .icon-is-missed {
      display: block;
    }
  }
`;

const DayText = styled.div`
  font-weight: 500;
  position: relative;
  z-index: 2;
  margin-bottom: 0.4rem;
  opacity: 0.5;
  padding: 0 0.1rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    height: 2px;
    right: 0;
    left: 0;
    z-index: 1;
  }

  &[data-is-today="true"] {
    opacity: 1;
    &::after {
      background-color: currentColor;
    }
  }
`;

const IconMaskChecked = () => (
  <svg
    className="icon-has-reading"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill="url(#markmissedgradient)"
      d="M12.375 21.027c.204 0 .39-.048.558-.143.175-.094.324-.24.448-.437l6.847-10.74a2.7 2.7 0 0 0 .197-.384.942.942 0 0 0 .098-.404c0-.277-.105-.5-.317-.667a1.072 1.072 0 0 0-.7-.252.883.883 0 0 0-.47.142 1.143 1.143 0 0 0-.383.405L12.32 18.609l-3.15-3.937c-.138-.197-.28-.328-.426-.394a1.037 1.037 0 0 0-.47-.11.933.933 0 0 0-.7.296.957.957 0 0 0-.274.69c0 .138.026.276.077.415.05.131.124.255.218.372l3.72 4.506c.16.211.324.36.491.448.175.088.365.132.569.132Z"
    />
    <defs>
      <linearGradient
        id="markmissedgradient"
        x1="7.3"
        x2="20.523"
        y1="14.513"
        y2="14.513"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0E3763" />
        <stop offset=".526" stop-color="#5B4855" />
        <stop offset="1" stop-color="#8A6947" />
      </linearGradient>
    </defs>
  </svg>
);

const IconMaskMissed = () => (
  <svg
    className="icon-is-missed"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill="#AAA7A0"
      d="M10.478 20.726a.929.929 0 0 1-.492.12.925.925 0 0 1-.667-.25.816.816 0 0 1-.263-.613c0-.226.073-.445.219-.657l3.73-5.239L9.45 9.09c-.175-.24-.263-.478-.263-.711 0-.255.095-.474.285-.656a.982.982 0 0 1 .689-.274c.211 0 .386.044.525.131.138.08.28.22.426.416l3.194 4.703h.077l3.16-4.692c.132-.204.267-.346.405-.427a.924.924 0 0 1 .503-.13c.27 0 .493.083.668.25.182.169.273.38.273.635 0 .219-.08.438-.24.656l-3.708 5.152 3.675 5.13c.153.211.23.434.23.667a.872.872 0 0 1-.274.656c-.182.168-.416.252-.7.252a.988.988 0 0 1-.503-.12c-.131-.088-.274-.241-.427-.46l-3.226-4.681h-.066l-3.248 4.681c-.153.219-.296.372-.427.46Z"
    />
  </svg>
);

const CalendarProgress: FC<{
  dates: Dayjs[];
  today: Dayjs;
  readings: Dayjs[];
  missed: Dayjs[];
}> = (props) => (
  <CalendarWrap>
    <HorizontalCalendar>
      {props.dates.map((d) => {
        const dayStr = d
          .toDate()
          .toLocaleString(getSupportedUserLocale(), {
            weekday: "short"
          })
          .slice(0, 2);
        const isToday = d.isSame(props.today, "day");
        const hasReading = props.readings.some((r) => r.isSame(d, "day"));
        const isMissed = props.missed.some((r) => r.isSame(d, "day"));

        const dayStringFull = d
          .toDate()
          .toLocaleString(getSupportedUserLocale(), { weekday: "long" });
        const statusTitle = hasReading
          ? translate("dailyReadings.aria.hasReadingDay", {
              weekday: dayStringFull
            })
          : isMissed
            ? translate("dailyReadings.aria.missedDay", {
                weekday: dayStringFull
              })
            : "";

        return (
          <DayItem
            key={d.toString()}
            aria-label={statusTitle}
            title={statusTitle}
          >
            <DayText data-is-today={String(isToday)}>{dayStr}</DayText>
            <IconSpace
              data-has-reading={String(hasReading)}
              data-is-missed={String(isMissed)}
              aria-hidden
            >
              {hasReading && <IconMaskChecked />}
              {isMissed && <IconMaskMissed />}
            </IconSpace>
          </DayItem>
        );
      })}
    </HorizontalCalendar>
  </CalendarWrap>
);

export default CalendarProgress;
