import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React, { FC } from "react";
import vecMask from "src/ui/assets/badge-mask.svg";
import innerRing from "src/ui/assets/badge-inner-ring.svg";
import iconWeightScale from "src/ui/assets/icon-weight-scale-gradient.svg";

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Badge = styled.div`
  width: 100%;
  position: relative;
  aspect-ratio: 1;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    aspect-ratio: 1;
    background-image: url(${innerRing});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 10;
  }

  img {
    position: absolute;
    inset: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 42%;
    aspect-ratio: 1;
    object-fit: cover;
  }
`;

const BadgeGraphics = styled.div`
  mask: url("${vecMask}");
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  mask-size: cover;
  --ani-speed: 10s;
  animation: ${rotateAnimation} var(--ani-speed) linear infinite;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: -4px;
    border-radius: 50%;
    animation: ${rotateAnimation} var(--ani-speed) linear infinite reverse;
    background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0.11) -27.71%,
        rgba(0, 0, 0, 0.11) 30.54%,
        rgba(255, 255, 255, 0.11) 82.19%,
        rgba(0, 0, 0, 0.11) 136.04%,
        rgba(255, 255, 255, 0.11) 183.29%
      ),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.4) 100%
      ),
      radial-gradient(
        150.41% 88.89% at 67.1% 8.5%,
        rgba(128, 174, 255, 0.79) 0%,
        #fff3c7 22.92%,
        #feb0fe 46.88%,
        #abb3fc 68.23%,
        #b0f2ce 87.5%,
        #80aeff 100%
      );
    background-blend-mode: difference, normal, normal;
  }
`;

const CompletedWeekBadge: FC<{ className?: string }> = (props) => {
  return (
    <Badge className={props.className}>
      <BadgeGraphics />
      <img src={iconWeightScale} alt="icon-weight-scale" />
    </Badge>
  );
};

export default CompletedWeekBadge;
