import React from "react";
import Translate from "../Translate/Translate";
import styled from "@emotion/styled";

const Title = styled.h4`
  font-family: var(--font-sans);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  color: var(--color-charcoal-60);
  margin-bottom: 0.8rem;
`;

const StyledDetails = styled.details`
  overflow: hidden;

  summary {
    display: block;
    position: relative;
    border-bottom: 2px solid var(--color-cream-dark);
    --icon-size: 2.2rem;

    &::-webkit-details-marker {
      display: none;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      padding: 0.6rem 0 0.55rem;
      line-height: 1.4;
      padding-right: calc(var(--icon-size) + 0.6rem);
      font-weight: 500;
    }

    svg {
      display: block;
      width: var(--icon-size);
      height: auto;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotateX(180deg);
    }
  }

  + .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0fr;
    opacity: 0;
    transition: grid-template-rows 0.3s ease-out;
    transition-property: grid-template-rows, opacity;

    .content-body {
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      border-bottom: 2px solid var(--color-cream-dark);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.4rem;
        background: linear-gradient(to bottom, transparent, var(--color-cream));
      }
    }

    .content-body-inner {
      padding: 0 0 1.4rem;
    }
  }

  &[open] {
    summary {
      border-bottom: 2px solid var(--color-cream);
      svg {
        transform: translateY(-50%) rotateX(0deg);
      }
    }

    + .content {
      grid-template-rows: 1fr;
      opacity: 1;
    }
  }
`;

const AccordionIconPointingUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
    <rect width="35" height="35" x=".5" y=".5" stroke="url(#acc-grad)" rx="17.5" />
    <path className="acc-arrow"
      fill="#212121"
      d="m17.992 16.36-5.282 4.695-.662-.746 5.944-5.285 5.944 5.285-.663.746-5.28-4.695Z"
    />
    <defs>
      <linearGradient
        id="acc-grad"
        x1="0"
        x2="35.998"
        y1="17.633"
        y2="17.377"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#80AEFF" />
        <stop offset=".527" stop-color="#F7BDE6" />
        <stop offset="1" stop-color="#FFBD70" />
      </linearGradient>
    </defs>
  </svg>
);

const DailyReadingsFaq = () => {
  const faqIndexStringArray = ["1", "2", "3", "4", "5"] as const;
  return (
    <div>
      <Title>
        <Translate msg="faq.dailyReadings.title" />
      </Title>
      {faqIndexStringArray.map((index) => {
        return (
          <div key={index}>
            <StyledDetails key={index}>
              <summary aria-details={`answer_${index}`} role="term">
                <span>
                  <Translate msg={`faq.dailyReadings.question_${index}`} />
                </span>
                <AccordionIconPointingUp />
              </summary>
            </StyledDetails>
            <div className="content" role="definition" id={`answer_${index}`}>
              <div className="content-body">
                <div className="content-body-inner">
                  <Translate
                    msg={`faq.dailyReadings.answer_${index}`}
                    markdown
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DailyReadingsFaq;
